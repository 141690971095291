<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <platform-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ platformName() }}
        </h2>
        <small
          v-if="platformVersionName()"
          class="pl-50"
        >
          {{ platformVersionName() }}
        </small>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('auth.forgotPassword.title') }}
          </b-card-title>

          <template v-if="selectedMethod === null">
            <b-card-text class="mb-2">
              {{ $t('auth.forgotPassword.select_method') }}
            </b-card-text>

            <b-button
              variant="primary"
              block
              @click="selectedMethod = 'email'"
            >
              <material-icon
                icon="Email"
                class="pr-50"
                size="20"
              />
              <span>{{ $t('auth.forgotPassword.methods.email') }}</span>
            </b-button>
            <b-button
              variant="primary"
              block
              @click="selectedMethod = 'whatsapp'"
            >
              <span class="pr-50"><svg
                id="Layer_1"
                fill="#ffffff"
                height="20px"
                width="20px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 308 308"
                xml:space="preserve"
              ><g
                id="SVGRepo_bgCarrier"
                stroke-width="0"
              /><g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              /><g id="SVGRepo_iconCarrier"> <g id="XMLID_468_"> <path
                id="XMLID_469_"
                d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"
              /> <path
                id="XMLID_470_"
                d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"
              /> </g> </g></svg></span>
              <span>{{ $t('auth.forgotPassword.methods.whatsapp') }}</span>
            </b-button>
          </template>

          <template v-if="selectedMethod === 'email'">
            <b-card-text class="mb-2">
              {{ $t('auth.forgotPassword.subtitle_email') }}
              <b-link
                class="underlined small"
                @click="selectedMethod = null"
              >{{ $t('auth.forgotPassword.methods.change') }}</b-link>
            </b-card-text>

            <!-- form -->
            <validation-observer ref="forgotPasswordForm">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="validationForm"
              >
                <b-form-group
                  :label="$t(`inputs.email`)"
                  label-for="forgot-password-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(`inputs.email`)"
                    rules="required|email"
                  >
                    <b-form-input
                      id="forgot-password-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-password-email"
                      placeholder="myemail@example.com"
                      :disabled="isLoading"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="isLoading"
                >
                  <template v-if="isLoading">
                    <b-spinner small />
                    <span class="sr-only">Loading...</span>
                  </template>
                  <span v-else>{{ $t('auth.forgotPassword.btn_title') }}</span>
                </b-button>
              </b-form>
            </validation-observer>
          </template>

          <template v-else-if="selectedMethod === 'whatsapp'">
            <b-card-text class="mb-2">
              {{ $t('auth.forgotPassword.subtitle_whatsapp') }}
              <b-link
                class="underlined small"
                @click="selectedMethod = null"
              >{{ $t('auth.forgotPassword.methods.change') }}</b-link>
            </b-card-text>

            <!-- form -->
            <validation-observer ref="forgotPasswordForm">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="validationForm"
              >
                <!-- mobile -->
                <i-input
                  v-model="userMobile"
                  :label="$t('inputs.mobile')"
                  :dial-code.sync="userMobileCode"
                  type="tel"
                  :disabled="isLoading"
                  :input-container-bindings="{
                    'vid': 'mobile'
                  }"
                  :numeric-placeholder="true"
                  :validation-rules="[required]"
                  required="required"
                />

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="isLoading"
                >
                  <template v-if="isLoading">
                    <b-spinner small />
                    <span class="sr-only">Loading...</span>
                  </template>
                  <span v-else>{{ $t('auth.forgotPassword.btn_title') }}</span>
                </b-button>
              </b-form>
            </validation-observer>
          </template>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t('auth.forgotPassword.back_to_login') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PlatformLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import {
  catchException, platformName, platformVersionName, toast,
} from '@core/utils/utils'
import useSession from '@/auth/jwt/useJwt'

export default {
  components: {
    BSpinner,
    PlatformLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      selectedMethod: null,
      userEmail: '',
      userMobile: null,
      userMobileCode: null,
      isLoading: false,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    platformVersionName,
    platformName,
    validationForm() {
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          useSession.csrf().then(() => {
            useSession.forgotPassword({
              method: this.selectedMethod,
              email: this.userEmail,
              mobile: this.userMobile,
              mobile_code: this.userMobileCode,
            })
              .then(response => {
                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace({ name: 'auth-login' })
                  .then(() => {
                    toast(
                      'success',
                      response.data.message,
                    )
                  })
              })
              .catch(error => {
                if (error?.response?.status === 422) {
                  toast('warning', error.response.data.message, null, 'AlertTriangleIcon')
                  this.$refs.registerForm.setErrors(error.response.data.errors)
                } else {
                  catchException(error)
                  toast('danger', error?.response?.data?.message || error.message/* , errorText */)
                }
              })
              .finally(() => {
                this.isLoading = false
              })
          }).catch(() => {
            this.isLoading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
